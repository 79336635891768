body,
html {
  font-family: $font-body;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.42;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @media (min-width: 576px) {
    font-size: 2.8rem;
    line-height: 1.42;
  }

  @media (min-width: 992px) {
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

html {
  font-size: 62.5%; // Sets up the Base 10 stuff
}

body {
  overflow-x: hidden;
}
