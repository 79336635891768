@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/fonts/icomoon.eot?n3c2qi');
  src: url('../fonts/icomoon/fonts/icomoon.eot?n3c2qi#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.woff2?n3c2qi') format('woff2'),
    url('../fonts/icomoon/fonts/icomoon.ttf?n3c2qi') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?n3c2qi') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?n3c2qi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-camera:before {
  content: '\e90c';
}
.icon-repeat-arrow:before {
  content: '\e90a';
}
.icon-body:before {
  content: '\e90b';
}
.icon-download:before {
  content: '\e907';
}
.icon-times:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e908';
}
.icon-minus:before {
  content: '\e905';
}
.icon-plus:before {
  content: '\e906';
}
.icon-play:before {
  content: '\e904';
}
.icon-chevron-down:before {
  content: '\e902';
}
.icon-external-link-alt:before {
  content: '\e903';
}
.icon-chevron-right:before {
  content: '\e900';
}
.icon-chevron-left:before {
  content: '\e901';
}
